import React from 'react'
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import MapFH from '../../images/map-fh.webp';
import MapNK from '../../images/map-nk.webp';
import PraxisFH from '../../images/praxis-fh.webp';
import PraxisNK from '../../images/praxis-nk.webp';

import './styles.scss';

export default function InfoSection() {
    const { t } = useTranslation();

  return (
    <section id="info">
        <h3>{t('Info.title')}</h3>
        <div className='info-section-container'>
            <div className='practice-info'>
                <h3>{t('Info.location.fh')}</h3>                
                <div className='infobox'>
                    <img src={PraxisFH} alt="praxis"/>
                    <h6>{t('Info.mapsource')}</h6>
                    <div>
                        <h4><Trans t={ t }>Info.adresse.fh</Trans></h4>
                        <h4><Trans t={ t }>Info.termine.fh</Trans></h4>
                    </div>
                </div>
                <Link to={'https://www.openstreetmap.org/search?query=Kreutziger%20Str.%2012%2C%2010247%20Berlin#map=19/52.51331/13.45951'} target={'_blank'}><img src={MapFH} alt="map"/></Link>
            </div>
            <div className='practice-info'>
                <h3 className='location-title-nk'>{t('Info.location.nk')}</h3>
                <div className='infobox'>
                    <img src={PraxisNK} alt="praxis"/>
                    <h6>{t('Info.mapsource')}</h6>
                    <div>
                        <h4><Trans t={ t }>Info.adresse.nk</Trans></h4>
                        <h4><Trans t={ t }>Info.termine.nk</Trans></h4>
                    </div>
                </div>
                <Link to={'https://www.openstreetmap.org/search?query=weichselplatz%204%20Berlin#map=19/52.48750/13.43869'} target={'_blank'}><img src={MapNK} alt="map"/></Link>
            </div>
        </div>
        <div className='contact-box'>
            <h4><b>{t('Info.email')}<br></br><Trans t={ t }>Info.mobil</Trans></b></h4>
        </div>
    </section>  
)
}
