import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import SusaImage from '../../images/susa1.webp'
import SusaImage2 from '../../images/susa2.webp'

import './styles.scss';

export default function AboutSusaSection() {
  const { t } = useTranslation();

  return (
    <section id="about-me">
          <img src={SusaImage} alt="susa-img"/>
          <p><Trans t={ t }>AboutMe.text</Trans></p>
          <div>          
            <h4>{t('AboutMe.list.title1')}</h4><h5>{t('AboutMe.list.subtitle1')}</h5>
            <h4>{t('AboutMe.list.title2')}</h4><h5>{t('AboutMe.list.subtitle2')}</h5>
            <h4>{t('AboutMe.list.title3')}</h4><h5>{t('AboutMe.list.subtitle3')}</h5>
            <h4>{t('AboutMe.list.title4')}</h4><h5>{t('AboutMe.list.subtitle4')}</h5>
          </div>    
          <img src={SusaImage2} alt="susa-img"/>
    </section>
  )
}
