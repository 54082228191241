import React, { Component, useLayoutEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.scss';

import Home from './pages/Home';
import Impressum from './pages/Impressum/Impressum';
import Privacy from './pages/Privacy/Privacy';
import Footer from './components/Footer/Footer';
import NavBar from './components/NavBar/NavBar';
import Collaborations from './pages/Collaborations/Collaborations';

const Wrapper = ({children}) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
} 

class App extends Component {
  render() {
    return (
      <Router>     
      <Wrapper>
        <div className="App">
          <NavBar />
          <Routes>
            <Route path='/' element={<Home />}/>
            <Route path='/impressum' element={<Impressum />}/>
            <Route path='/datenschutz' element={<Privacy />}/>
            <Route path='/collaborations' element={<Collaborations />}/>
          </Routes>
          <Footer />
        </div>
      </Wrapper> 
      </Router>
    );
  }
}

export default App;
