import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import BurgerMenu from '../../images/burger-bar.png'
import TransButton from '../TransButton/TransButton';

import LogoWithTitle from '../../images/logo-title.webp'

import './styles.scss';

export default function NavBar() {
    const { t } = useTranslation();
    const [openMenu, setOpenMenu] = useState(false)

  return (
    <nav id='nav'>
          <div className='nav-header'>
            <a href='/'><img src={LogoWithTitle} alt="Logo"/></a>
            <div>
              <img src={BurgerMenu} className='nav-menu-icon' onClick={() => setOpenMenu(!openMenu)}/>
              <TransButton/>
            </div>
          </div>
          <div>
            {openMenu && 
            <div className='nav-open-menu'>
              <a href='/#about-osteo' onClick={() => setOpenMenu(false)}><h5>{t('NavBar.Osteopathie')}</h5></a>
              <a href='/#treatment' onClick={() => setOpenMenu(false)}><h5>{t('NavBar.Behandlung')}</h5></a>
              <a href='/#pricing' onClick={() => setOpenMenu(false)}><h5>{t('NavBar.Kosten')}</h5></a>
              <a href='/#about-me'onClick={() => setOpenMenu(false)}><h5>{t('NavBar.ÜberMich')}</h5></a>
              <Link to={'/collaborations'} onClick={() => setOpenMenu(false)}><h5>Collaborations</h5></Link>
            </div>}
            <div className='nav-desktop-menu'>
              <a href='/#about-osteo'><h5>{t('NavBar.Osteopathie')}</h5></a>
              <a href='/#treatment' ><h5>{t('NavBar.Behandlung')}</h5></a>
              <a href='/#pricing'><h5>{t('NavBar.Kosten')}</h5></a>
              <a href='/#about-me'><h5>{t('NavBar.ÜberMich')}</h5></a>
              <Link to={'/collaborations'}><h5>Collaborations</h5></Link>
            </div>
          </div>
    </nav>
  )
}
