import React, { useState } from 'react'
import { useTranslation, Trans } from 'react-i18next';

import ArrowDown from '../../images/arrow-down.png'
import ArrowUp from '../../images/arrow-up.png' 

import './styles.scss';

export default function AccordionItem ({ title, description,text, className, image, altText}) {
    const { t } = useTranslation();

    const [showText, setShowText] = useState(false)

    return (
    <div id='accordion-item' className={className}>
        <h2>{t(title)}</h2>
        <img className={`title-img ${showText && 'border-radius'}`} src={image} alt={altText}/>
        <div className={showText ? 'open' : 'closed'} onClick={() => {setShowText(!showText)}}>
            <img className='arrow-icon' src={showText? ArrowUp : ArrowDown} alt='clickable  arrow pointing down'/>
        </div>
        {showText && 
        <div className='open-card'>
            <p>{t(description)}</p>
            <hr></hr>
            <div>
                <p>{t('AboutOsteo.categories.applied')}</p>
                <p><Trans t={ t }>{text}</Trans></p>
            </div>
        </div>}
    </div>
  )
}
