import React from 'react'
import { useTranslation, Trans } from 'react-i18next';

import './styles.scss';

export default function CTA({message}) {
    const { t } = useTranslation();

  return (
    <a className='doctolib-btn' href="https://www.doctolib.de/osteopath/berlin/susanna-nitz?utm_campaign=website-button&amp;utm_source=susanna-nitz-website-button&amp;utm_medium=referral&amp;utm_content=option-8&amp;utm_term=susanna-nitz"  rel="noopener noreferrer" target="_blank">
      <span><Trans t={ t }>{message}</Trans></span>
      <img src="https://www.doctolib.de/external_button/doctolib-white-transparent.png" alt="Doctolib"/>
    </a>
  )
}
