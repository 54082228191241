import React from 'react'
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next'

import Logo from '../../images/logo.webp' 

import './styles.scss';

export default function Footer() {
  const { t } = useTranslation();

  return (
    <footer id="footer">
        <img src={Logo} alt="Logo" className='logo-end'/>
        <div>
          <div className='footer-address-box'>
            <div><h4><Trans t={ t }>Footer.adresse.fh</Trans></h4><h4><Trans t={ t }>Info.termine.fh</Trans></h4></div>
            <div><h4><Trans t={ t }>Footer.adresse.nk</Trans></h4><h4><Trans t={ t }>Info.termine.nk</Trans></h4></div>
          </div>
          <h4>{t('Info.email')}</h4>             
          <h4><Trans t={ t }>Info.mobil</Trans></h4>
        </div>
        <div className='disclaimer'>
          <Link to={"/impressum"}><h4>{t('Footer.impressum')}</h4></Link>
          <h4>|</h4>
          <Link to={"/datenschutz"}><h4>{t('Footer.datenschutz')}</h4></Link>            
        </div>
    </footer>
  )
}
