import React from 'react'
import { useTranslation } from 'react-i18next';

import './styles.scss'

export default function Collaborations() {
  const { t } = useTranslation();

  return (
      <div id='collaborations' className='grid-wrapper'>
          <h1>{t('Collaborations.title')}</h1>
          <p>{t('Collaborations.comingsoon')}</p>
      </div>
  )
}
