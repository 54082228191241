import React from 'react'

import AccordionItem from '../../components/AccordionItem/AccordionItem';


import Viszeral from '../../images/viszeral.webp'
import Kranial from '../../images/kranial.webp'
import Parietal from '../../images/parietal.webp'

import './styles.scss'

export default function Accordion() {

    return (
    <div id='accordion'>
        <AccordionItem title={'AboutOsteo.categories.title1'} description={'AboutOsteo.categories.description1'} text={'AboutOsteo.categories.text1'} image={Viszeral}/>
        <AccordionItem title={'AboutOsteo.categories.title2'} description={'AboutOsteo.categories.description2'} text={'AboutOsteo.categories.text2'} image={Kranial}/>
        <AccordionItem title={'AboutOsteo.categories.title3'} description={'AboutOsteo.categories.description3'} text={'AboutOsteo.categories.text3'} image={Parietal}/>
    </div>
  )
}
