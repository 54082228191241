import React from 'react'
import { Trans, useTranslation } from 'react-i18next';

import Accordion from '../../components/Accordion/Accordion';

import './styles.scss';

export default function AboutOsteo() {
    const { t } = useTranslation();
   
    return (
        <section id="about-osteo">
            <div>
                <h1>{t('AboutOsteo.title')}</h1>
                <h3>{t('AboutOsteo.subtitle')}</h3>
            </div>
            <div><Trans t={ t }>AboutOsteo.text</Trans></div>
            <div className='osteo-variants'>
                <p>{t('AboutOsteo.text2')}</p>
                <Accordion />
            </div>
        </section>
)}
