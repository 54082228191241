import React from 'react'
import { useTranslation } from 'react-i18next'

// import LandingCarousel from '../../components/LandingCarousel/LandingCarousel';

import './styles.scss';

export default function LandingSection() {
  const { t } = useTranslation();

  return (
    <section id='landing'>
      {/* <LandingCarousel/> */}
      <div className='landing-text'>
        <h1>{t('Landing.Greeting')}</h1>
        <h2>{t('Landing.Header.Osteopathie')}<br></br>{t('Landing.Header.Name')}</h2>
        <h3>{t('Landing.Header.Location')}</h3>
      </div>
    </section>
  )
}
