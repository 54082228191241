import React from 'react'

import CTA from '../components/CTA/CTA'
import PricingSection from '../sections/PricingSection/PricingSection';
import TreatmentSection from '../sections/TreatmentSection/TreatmentSection';
import InfoSection from '../sections/InfoSection/InfoSection';
import LandingSection from '../sections/LandingSection/LandingSection';
import AboutOsteo from '../sections/AboutOsteoSection/AboutOsteoSection';
import AboutMeSection from '../sections/AboutMeSection/AboutMeSection';

import './styles.scss';

export default function Home() {
  return (
    <div className='grid-wrapper'>
      <CTA message={'CTA.Termin'}/>
      <LandingSection />
      <InfoSection />
      <AboutOsteo />
      <TreatmentSection />
      <PricingSection />
      <AboutMeSection />
    </div>
  )
}
